<template>
  <div></div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "CertificateDownload",
  data() {
    return {
      images: {},
      imageCount: 2,
      currentImageCount: 0,
    };
  },
  props: {
    locale: {
      type: String,
      default: "de",
    },
  },
  watch: {
    currentImageCount: function (nVal) {
      if (nVal == this.imageCount) {
        this.pdfgen();
        this.$store.commit("loadingFlow");
      }
    },
  },
  computed: {
    ...mapGetters({
      profile: "user/profile/current",
      user: "user/current",
    }),
    imagesLoaded() {
      return this.currentImageCount == this.imageCount;
    },
    certDate() {
      return moment(new Date())
        .locale(this.locale)
        .format(this.$t("locale.textDateFormat", this.locale));
    },
    birthdate() {
      return moment(new Date(new Date(this.profile.birthdate * 1000))).format(
        this.$t("locale.dateFormat", this.locale)
      );
    },
    registerDate() {
      return moment(new Date(this.user.modified.created * 1000))
        .locale(this.locale)
        .format(this.$t("locale.textMonthDateFormat", this.locale));
    },
  },
  methods: {
    download() {
      if (!this.imagesLoaded) {
        this.$store.commit("loadingFlow");
        var that = this;
        this.toDataURL(
          process.env.VUE_APP_BACKEND_URL + "/assets/logo_default.png",
          function (data) {
            that.images.logo = data;
            that.currentImageCount++;
          }
        );
        this.toDataURL(
          process.env.VUE_APP_BACKEND_URL + "/assets/signature_cs.png",
          function (data) {
            that.images.signature = data;
            that.currentImageCount++;
          }
        );
      } else {
        this.pdfgen();
      }
    },
    toDataURL: function (url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.open("get", url);
      xhr.setRequestHeader("Content-Type", "image/png");
      xhr.withCredentials = true;
      xhr.responseType = "blob";
      xhr.onload = function () {
        var fr = new FileReader();
        fr.onload = function () {
          callback(this.result);
        };
        fr.readAsDataURL(xhr.response); // async call
      };
      xhr.send();
    },
    pdfgen: function () {
      var pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        //userPassword: '123',
        ownerPassword: "123456",
        permissions: {
          printing: "lowResolution",
          modifying: false,
          copying: false,
          annotating: true,
          fillingForms: true,
          contentAccessibility: false,
          documentAssembly: true,
        },
        // inhalt des PDFs
        content: [
          {
            // Logo image
            image: this.images.logo,
            width: 120,
            height: 60,
            absolutePosition: { x: 420, y: 40 },
          },
          {
            // Image signature
            image: this.images.signature,
            width: 100,
            height: 55,
            absolutePosition: {
              x: 60,
              y: this.locale == "de" ? 490 : 460,
            },
          },
          // Organisation stuff
          {
            text: this.$t(
              "profile.certificate.organisation.header.address",
              this.locale
            ),
            style: "header_organisation_address",
            absolutePosition: { x: 420, y: 120 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.header.phone.label",
              this.locale
            ),
            style: "header_label",
            absolutePosition: { x: 420, y: 146 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.header.phone.value",
              this.locale
            ),
            style: "header_value",
            absolutePosition: { x: 450, y: 146 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.header.fax.label",
              this.locale
            ),
            style: "header_label",
            absolutePosition: { x: 420, y: 158 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.header.fax.value",
              this.locale
            ),
            style: "header_value",
            absolutePosition: { x: 450, y: 158 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.header.mail.label",
              this.locale
            ),
            style: "header_label",
            absolutePosition: { x: 420, y: 170 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.header.mail.value",
              this.locale
            ),
            style: "header_value",
            absolutePosition: { x: 450, y: 170 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.header.website",
              this.locale
            ),
            style: "header_organisation_website",
            absolutePosition: { x: 420, y: 180 },
          },
          {
            text: this.$t("profile.certificate.certificate_date", this.locale, {
              0: this.certDate,
            }),
            style: "certificate_date",
            absolutePosition: { x: 400, y: 220 },
          },
          {
            text: this.$t(
              "profile.certificate.organisation.inline",
              this.locale
            ),
            style: "inline_organisation",
            absolutePosition: { x: 50, y: 170 },
          },
          {
            text:
              this.user.full_name +
              "\n" +
              this.user.address.street +
              " " +
              this.user.address.number +
              "\n" +
              this.user.address.zip +
              " " +
              this.user.address.city,
            style: "supporter",
            absolutePosition: { x: 50, y: 190 },
          },
          {
            text: this.$t("profile.certificate.headline", this.locale, {
              0: this.user.full_name,
            }),
            style: "headline",
            absolutePosition: { x: 50, y: 260 },
          },
          {
            text: this.$t("profile.certificate.body.1", this.locale, {
              0: this.user.full_name,
              1: this.birthdate,
              2: this.registerDate,
            }),
            style: "body",
          },
          {
            text: this.$t("profile.certificate.body.2", this.locale, {
              0: this.user.full_name,
              1: this.user.crew.name,
            }),
            style: "paragraph",
          },
          {
            text: this.$t("profile.certificate.body.3", this.locale, {
              0: this.user.full_name,
            }),
            style: "paragraph",
          },
          {
            text: this.$t("profile.certificate.body.greetings", this.locale),
            style: "paragraph",
          },
          {
            text: this.$t("profile.certificate.body.signature", this.locale),
            style: "signature",
          },
        ],
        footer: {
          columns: [
            {
              text: this.$t(
                "profile.certificate.organisation.footer.account",
                this.locale
              ),
              style: "footer_highlight",
              absolutePosition: { x: 50, y: 0 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.iban.label",
                this.locale
              ),
              style: "footer_label",
              absolutePosition: { x: 50, y: 10 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.iban.value",
                this.locale
              ),
              style: "footer_value",
              absolutePosition: { x: 80, y: 10 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.bic.label",
                this.locale
              ),
              style: "footer_label",
              absolutePosition: { x: 50, y: 20 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.bic.value",
                this.locale
              ),
              style: "footer_value",
              absolutePosition: { x: 80, y: 20 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.legal.label",
                this.locale
              ),
              style: "footer_highlight",
              absolutePosition: { x: 240, y: 0 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.legal.value",
                this.locale
              ),
              style: "footer_value",
              absolutePosition: { x: 330, y: 0 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.register.label",
                this.locale
              ),
              style: "footer_highlight",
              absolutePosition: { x: 240, y: 10 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.register.value",
                this.locale
              ),
              style: "footer_value",
              absolutePosition: { x: 330, y: 10 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.court",
                this.locale
              ),
              style: "footer_value",
              absolutePosition: { x: 240, y: 20 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.tax_number.label",
                this.locale
              ),
              style: "footer_highlight",
              absolutePosition: { x: 440, y: 0 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.tax_number.value",
                this.locale
              ),
              style: "footer_value",
              absolutePosition: { x: 500, y: 0 },
            },
            {
              text: this.$t(
                "profile.certificate.organisation.footer.tax_office",
                this.locale
              ),
              style: "footer_value",
              absolutePosition: { x: 440, y: 10 },
            },
          ],
        },
        styles: {
          // Header stuff
          header_organisation_address: {
            fontSize: 9,
            lineHeight: 1.2,
            alignment: "left",
          },
          header_label: {
            fontSize: 9,
            bold: true,
            alignment: "left",
          },
          header_value: {
            fontSize: 9,
            alignment: "left",
          },
          header_organisation_website: {
            fontSize: 15,
            bold: true,
            color: "#008fc3",
            alignment: "left",
          },
          inline_organisation: {
            fontSize: 8,
            bold: true,
            alignment: "left",
            color: "#008fc3",
          },
          certificate_date: {
            fontSize: 11,
            alignment: "right",
          },
          headline: {
            fontSize: 13,
            alignment: "left",
            color: "#008fc3",
          },
          body: {
            fontSize: 11,
            lineHeight: 1.2,
            alignment: "justify",
            margin: [10, 260, 10, 0],
          },
          paragraph: {
            fontSize: 11,
            lineHeight: 1.2,
            alignment: "justify",
            margin: [10, 5, 0, 10],
          },
          signature: {
            fontSize: 11,
            lineHeight: 1.2,
            margin: [10, 50, 30, 10],
          },
          footer_highlight: {
            fontSize: 8,
            bold: true,
            alignment: "left",
            color: "#008fc3",
          },
          footer_label: {
            fontSize: 8,
            bold: true,
            alignment: "left",
          },
          footer_value: {
            fontSize: 8,
            alignment: "left",
          },
        },
      };
      pdfMake
        .createPdf(docDefinition)
        .download(this.$t("profile.certificate.filename", this.locale));
    },
  },
};
</script>
