<template>
  <vca-card>
    <h2>{{ $t("profile.settings.header") }}</h2>
    <Settings />
  </vca-card>
</template>

<script>
import Settings from "@/components/account/AccountSettings";
export default {
  name: "AccountSettings",
  components: { Settings },
};
</script>
