<template>
  <div>
    <div>
      <h3 v-if="system">{{ $t("profile.roles.system") }}</h3>
      <div class="tags-container" v-for="role in system" :key="role.id">
        <div class="tag">
          <div class="tag-label">{{ role.label }}</div>
        </div>
      </div>
    </div>
    <div v-if="pool">
      <h3>{{ $t("profile.roles.pool") }}</h3>
      <div class="tags-container tags-blue" v-for="role in pool" :key="role.id">
        <div class="tag">
          <div class="tag-label">{{ role.label }}</div>
          <div class="cancel" @click="removeRole(role)">X</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AccountRoles",
  computed: {
    ...mapGetters({
      system: "user/roles/system",
      pool: "user/roles/pool",
    }),
  },
  methods: {
    removeRole(value) {
      if (!confirm(this.$t("profile.edit.roles.confirm", { 0: value.label }))) {
        return false;
      }
      this.$store.commit("user/roles/current", value);
      this.$store.dispatch("user/roles/demote").then(() => {
        this.$store.dispatch("refresh");
      });
    },
  },
};
</script>
