<template>
  <div>
    <h3>
      {{ $t("profile.change.header") }} ({{ $t("profile.change.created") }}
      {{ date(user.modified.created) }})
    </h3>
    <p>{{ $t("profile.change.description") }}</p>
    <button class="vca-button" @click="navigate('/email/edit')">
      {{ $t("profile.change.email") }}
    </button>
    <button class="vca-button" @click="navigate('/password/edit')">
      {{ $t("profile.change.password") }}
    </button>
    <button class="vca-button" @click="navigate('/user/edit')">
      {{ $t("profile.change.name") }}
    </button>

    <p>
      {{ $t("profile.edit.profile.delete.description") }}
    </p>
    <div class="vca-center">
      <vca-cancel-button
        @click="deleteProfile()"
        :placeholder="$t('button.delete')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AccountChange",
  methods: {
    navigate(path) {
      window.location.href =
        process.env.VUE_APP_LOGIN_URL +
        path +
        "/?callback=" +
        window.btoa(window.location.href);
    },
    deleteProfile() {
      if (!confirm(this.$t("profile.edit.profile.delete.confirm"))) {
        return false;
      }
      this.$store.commit("suppressMsg", true);
      this.$store.commit("users/current", this.user);
      this.$store
        .dispatch({
          type: "users/deleteReq",
        })
        .then(() => {
          this.$store.commit("suppressMsg", false);

          this.notification({
            title: "messages.title.success",
            body: this.$t("messages.user.account_deleted"),
            type: "success",
          });
          setTimeout(() => {
            this.$router.push("/logout");
          }, 5000);
        });
    },
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
  },
};
</script>
