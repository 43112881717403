<template>
  <div>
    <h3>{{ $t("profile.nvm.title") }}</h3>
    <p class="green" v-if="nvmActive">
      {{ $t("profile.nvm.current.active") }}
    </p>
    <p class="red" v-if="nvmExpired">
      {{ $t("profile.nvm.current.expired") }}
    </p>
    <p class="orange" v-if="canApply">
      {{ $t("profile.nvm.current.inactive") }}
    </p>
    <p class="red" v-if="isDenied">
      {{ $t("profile.nvm.current.denied") }}
    </p>
    <p>{{ $t("profile.nvm.description") }}</p>
    <vca-card class="shadowed">
      <a
        :href="articleOfAssociationLink"
        :title="$t('profile.nvm.aoa')"
        target="_blank"
        >{{ $t("profile.nvm.aoa") }}</a
      >
    </vca-card>

    <div v-if="nvmActive">
      <p>{{ $t("profile.nvm.status.active") }}</p>
      <div class="vca-center">
        <vca-cancel-button
          @click="withdrawNVM()"
          :placeholder="$t('profile.nvm.actions.active')"
        />
      </div>
    </div>

    <div v-if="nvmExpired">
      <p>{{ $t("profile.nvm.status.renew") }}</p>
      <div class="vca-center">
        <button @click="confirmNVM" class="vca-button-small">
          {{ $t("profile.nvm.actions.renew") }}
        </button>
      </div>
    </div>

    <div v-if="canApply">
      <p>{{ $t("profile.nvm.status.apply") }}</p>
      <p>{{ $t("profile.nvm.status.confirmation") }}</p>
      <div class="vca-center">
        <button @click="confirmNVM" class="vca-button-small">
          {{ $t("profile.nvm.actions.apply") }}
        </button>
      </div>
    </div>
    <div v-if="isDenied">
      <p class="bold">{{ $t("profile.nvm.status.important") }}</p>
      <p v-if="!isActive">{{ $t("profile.nvm.status.inactive") }}</p>
      <p v-if="!hasCrew">{{ $t("profile.nvm.status.no_crew") }}</p>
      <p v-if="!hasBirthdate">
        {{ $t("profile.nvm.status.no_birthdate") }}
      </p>
      <p v-if="!hasAddress">{{ $t("profile.nvm.status.no_address") }}</p>
      <div class="vca-center">
        <button class="vca-button-small" disabled>
          {{ $t("profile.nvm.actions.cannot_apply") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NonvotingMembership",
  data() {
    return {
      articleOfAssociationLink: this.$t("profile.nvm.link"),
    };
  },
  methods: {
    confirmNVM() {
      if (!confirm(this.$t("profile.nvm.messages.confirm"))) {
        return false;
      }
      this.$store.dispatch({ type: "user/nvm/confirm" }).then(() => {
        this.$store.dispatch("refresh");
      });
    },
    withdrawNVM() {
      if (!confirm(this.$t("profile.nvm.messages.withdraw"))) {
        return false;
      }
      this.$store.dispatch({ type: "user/nvm/withdraw" }).then(() => {
        this.$store.dispatch("refresh");
      });
    },
  },
  computed: {
    isDenied() {
      return !this.canApply && !this.nvmExpired && !this.nvmActive;
    },
    canApply() {
      return (
        this.hasAddress && this.isActive && !this.nvmActive && this.hasBirthdate
      );
    },
    ...mapGetters({
      isActive: "user/active/isActive",
      nvmActive: "user/nvm/isActive",
      nvmExpired: "user/nvm/isExpired",
      isWithdrawn: "user/nvm/isWithdrawn",
      hasBirthdate: "user/hasBirthdate",
      hasCrew: "user/crew/hasCrew",
      hasAddress: "user/address/hasAddress",
    }),
  },
};
</script>
