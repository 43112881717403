<template>
  <div>
    <vca-row>
      <vca-card class="shadowed">
        <ActiveFlag />
        <NonvotingMembership />
        <Certificate />
      </vca-card>
      <vca-card class="shadowed">
        <Roles />
        <Change />
      </vca-card>
    </vca-row>
  </div>
</template>

<script>
import Change from "@/components/account/settings/Change";
import NonvotingMembership from "@/components/account/settings/NonvotingMembership";
import ActiveFlag from "@/components/account/settings/ActiveFlag";
import Certificate from "@/components/account/settings/Certificate";
import Roles from "@/components/account/settings/Roles";
export default {
  name: "AccountSettings",
  components: {
    Change,
    NonvotingMembership,
    ActiveFlag,
    Roles,
    Certificate,
  },
};
</script>
